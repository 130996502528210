import React from "react";
import { useNavigate } from "react-router-dom";

const ThankYouPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="fixed inset-0 bg-[#faf9f7] z-50 overflow-y-auto">
      <div className="max-w-2xl mx-auto px-6 py-8">
        <div className="flex justify-between items-center mb-12">
          <span className="text-red-800 font-serif text-2xl fixed left-8 top-8">
            Avenida
          </span>
        </div>

        <div className="text-center space-y-8">
          <h1 className="text-4xl font-serif">Thank You!</h1>
          <div className="space-y-4">
            <p className="text-sm text-gray-600 max-w-md mx-auto">
              Your membership application has been submitted successfully. We are
              looking forward to reviewing it and will get back to you soon!
            </p>
            <div className="bg-blue-50 rounded-lg p-4 max-w-md mx-auto">
              <p className="text-sm text-blue-800">
                Once your application is accepted, you will receive an email with a secure payment link to complete your membership registration.
              </p>
            </div>
          </div>
          
          <button
            onClick={() => navigate("/")}
            className="px-12 py-3 bg-black text-white rounded-full font-medium hover:bg-gray-800 transition-colors"
          >
            Back to Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
