import React, { useState, useEffect } from "react";
import { ChevronLeft, X } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { supabase } from '../config/supabase';
import AvenidaTrans from '../assets/AvenidaTrans.png';

interface InviteFormData {
  picture: File | null;
  bio: string;
  interests: string[];
  age: string;
  email: string;
  name: string;
  instagram: string;
}

interface EventDetails {
  title: string;
  event_date: string;
  location: string;
  event_fee: number;
  service_fee: number;
}

interface EventData {
  event_id: string;
  title: string;
  event_date: string;
  location: string;
  invite_configuration: {
    requires_payment: boolean;
    requires_picture: boolean;
    requires_bio: boolean;
    requires_interests: boolean;
    event_fee: number;
    service_fee: number;
    max_uses: number | null;
    usage_count: number;
  };
}

const INITIAL_FORM_DATA: InviteFormData = {
  picture: null,
  bio: "",
  interests: [],
  age: "",
  email: "",
  name: "",
  instagram: "",
};

const InterestPill = ({
  interest,
  onRemove,
}: {
  interest: string;
  onRemove: () => void;
}) => (
  <div className="inline-flex items-center gap-1 px-3 py-1 bg-gray-100 rounded-full text-sm">
    {interest}
    <button onClick={onRemove} type="button" aria-label="Remove interest">
      <X className="w-3 h-3" />
    </button>
  </div>
);

const ExternalInvite: React.FC = () => {
  const { eventId } = useParams();
  const searchParams = new URLSearchParams(window.location.search);
  const inviteCode = searchParams.get('code');
  const navigate = useNavigate();

  const [formData, setFormData] = useState<InviteFormData>(INITIAL_FORM_DATA);
  const [currentInterest, setCurrentInterest] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [eventDetails, setEventDetails] = useState<EventDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState<EventData | null>(null);

  useEffect(() => {
    const fetchEventDetails = async () => {
      if (!eventId || !inviteCode) {
        setError("Invalid invite link");
        setLoading(false);
        return;
      }

      try {
        console.log('Fetching event details:', { eventId, inviteCode });

        // Fetch event with invite link info
        const { data: eventData, error: eventError } = await supabase
          .from("events")
          .select("*")
          .eq("event_id", eventId)
          .eq("invite_link_code", inviteCode)
          .eq("invite_link_enabled", true)
          .single();

        if (eventError || !eventData) {
          throw new Error("Invalid or expired invite link");
        }

        // Check if max uses has been reached
        if (eventData.invite_configuration?.max_uses && 
            eventData.invite_configuration.usage_count >= eventData.invite_configuration.max_uses) {
          throw new Error("This invite link has reached its maximum usage limit");
        }

        console.log('Event data received:', eventData);

        setEvent(eventData);
        setEventDetails({
          title: eventData.title,
          event_date: eventData.event_date,
          location: eventData.location,
          event_fee: eventData.invite_configuration.event_fee,
          service_fee: eventData.invite_configuration.service_fee
        });
      } catch (err) {
        console.error("Error fetching event details:", err);
        setError(err instanceof Error ? err.message : "Failed to load event details");
      } finally {
        setLoading(false);
      }
    };

    fetchEventDetails();
  }, [eventId, inviteCode]);

  const validateForm = (): string | null => {
    if (!event) return "Invalid event";

    if (!formData.name.trim()) return "Name is required.";
    if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email))
      return "A valid email is required.";
    if (!formData.age.trim()) return "Age is required.";
    
    if (event.invite_configuration.requires_bio && !formData.bio.trim()) 
      return "Note is required.";
    if (event.invite_configuration.requires_interests && formData.interests.length === 0) 
      return "At least one interest is required.";
    if (event.invite_configuration.requires_picture && !formData.picture) 
      return "Profile picture is required.";

    return null;
  };

  const handlePictureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFormData((prev) => ({ ...prev, picture: file }));
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const addInterest = (interest: string) => {
    if (
      interest.trim() &&
      !formData.interests.includes(interest.trim())
    ) {
      setFormData((prev) => ({
        ...prev,
        interests: [...prev.interests, interest.trim()],
      }));
      setCurrentInterest("");
    }
  };

  const removeInterest = (indexToRemove: number) => {
    setFormData((prev) => ({
      ...prev,
      interests: prev.interests.filter((_, index) => index !== indexToRemove),
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      return;
    }
    
    if (!eventId || !event) {
      setError("Invalid event");
      return;
    }

    setIsSubmitting(true);
    setError(null);

    console.log('Submitting form with data:', formData);

    try {
      // Upload profile picture if provided
      let pictureUrl = null;
      if (formData.picture) {
        console.log('Uploading profile picture...');
        const fileExt = formData.picture.name.split('.').pop();
        const fileName = `${Math.random()}.${fileExt}`;
        const { data: uploadData, error: uploadError } = await supabase.storage
          .from('external-avatars')
          .upload(fileName, formData.picture);

        if (uploadError) {
          console.error('Picture upload error:', uploadError);
          throw uploadError;
        }
        pictureUrl = uploadData.path;
        console.log('Picture uploaded successfully:', pictureUrl);
      }

      // Determine if payment is required
      const requiresPayment = !!event.invite_configuration.requires_payment;
      
      // 1. Create external user record
      const userData = {
        name: formData.name,
        email: formData.email,
        instagram: formData.instagram,
        age: parseInt(formData.age),
        bio: formData.bio,
        interests: formData.interests,
        picture_url: pictureUrl,
        from_paid_invite: requiresPayment // Now this column exists in the database
      };
      
      console.log('Creating external user with data:', userData);
      
      // Create user with the from_paid_invite field
      const { data: externalUser, error: externalUserError } = await supabase
        .from('external_users')
        .insert([userData])
        .select()
        .single();

      if (externalUserError) {
        console.error('External user creation error:', externalUserError);
        throw new Error(`Failed to create user: ${externalUserError.message}`);
      }
      
      if (!externalUser) {
        throw new Error('External user creation returned no data');
      }
      
      console.log('Created external user with ID:', externalUser.id);
      
      // 2. Create event attendee record for the external user
      console.log('Creating event attendee record with:', {
        event_id: eventId,
        external_user_id: externalUser.id,
        status_id: 2, // pending status
        payment_status: event.invite_configuration.requires_payment ? 'pending' : 'completed',
        payment_amount: event.invite_configuration.event_fee + event.invite_configuration.service_fee,
        invite_required_payment: event.invite_configuration.requires_payment // Store the original invite link payment requirement
      });
      
      const { data: attendeeData, error: attendeeError } = await supabase
        .from('event_attendees')
        .insert([
          {
            event_id: eventId,
            external_user_id: externalUser.id,
            status_id: 2, // pending status
            payment_status: event.invite_configuration.requires_payment ? 'pending' : 'completed',
            payment_amount: event.invite_configuration.event_fee + event.invite_configuration.service_fee,
            invite_required_payment: event.invite_configuration.requires_payment // Store the original invite link payment requirement
          }
        ])
        .select();

      if (attendeeError) {
        console.error('Attendee record creation error:', attendeeError);
        throw attendeeError;
      }
      
      console.log('Created attendee record:', attendeeData);
      
      // 3. Update usage count in event configuration
      const newUsageCount = (event.invite_configuration.usage_count || 0) + 1;
      const updatedConfig = {
        ...event.invite_configuration,
        usage_count: newUsageCount
      };
      
      console.log('Updating event usage count to:', newUsageCount);
      
      const { data: updateData, error: updateError } = await supabase
        .from('events')
        .update({ 
          invite_configuration: updatedConfig
        })
        .eq('event_id', eventId)
        .select();

      if (updateError) {
        console.error('Event usage count update error:', updateError);
        throw updateError;
      }
      
      console.log('Updated event configuration:', updateData);
      
      setFormData(INITIAL_FORM_DATA);
      setPreviewUrl(null);
      navigate("/invite-thank-you");
    } catch (err) {
      console.error("Error submitting form:", err);
      setError(`Failed to submit the form: ${err instanceof Error ? err.message : "Unknown error"}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePictureClick = () => {
    document.getElementById('picture-input')?.click();
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-[#F0EFE9] flex items-center justify-center">
        <div className="text-gray-600">Loading...</div>
      </div>
    );
  }

  if (error && !eventDetails) {
    return (
      <div className="min-h-screen bg-[#F0EFE9] flex items-center justify-center">
        <div className="text-red-600">{error}</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#F0EFE9] flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-[#faf9f7] p-8 rounded-lg shadow">
        <div className="text-center space-y-1">
          <img
            className="mx-auto h-10 w-auto"
            src={AvenidaTrans}
            alt="Avenida"
          />
          <div className="mt-2 flex items-center justify-center text-sm text-gray-400 font-light">
            <span className="text-lg">×</span>
          </div>
          <h2 className="text-xl font-serif text-red-800 tracking-wider uppercase">
            {eventDetails?.title || 'Event Not Found'}
          </h2>
          <div className="mt-8 text-center text-2xl font-serif text-gray-900">
            External Invite Form
          </div>
        </div>

        {loading ? (
          <div className="text-center py-4">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 mx-auto"></div>
            <p className="mt-2 text-gray-600">Loading invite details...</p>
          </div>
        ) : error ? (
          <div className="rounded-md bg-red-50 p-4 text-center">
            <div className="text-sm text-red-700">{error}</div>
          </div>
        ) : (
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div className="space-y-4">
              {event?.invite_configuration.requires_picture && (
                <div>
                  <label htmlFor="picture" className="block text-sm font-medium text-gray-700">
                    Profile Picture
                  </label>
                  <div className="mt-1 flex justify-center">
                    <div
                      onClick={handlePictureClick}
                      className="w-32 h-32 relative cursor-pointer group"
                    >
                      {previewUrl ? (
                        <img
                          src={previewUrl}
                          alt="Preview"
                          className="w-32 h-32 object-cover border-2 border-gray-200 rounded-lg transition-all duration-200 group-hover:opacity-75"
                        />
                      ) : (
                        <div className="w-32 h-32 bg-gray-100 border-2 border-dashed border-gray-300 rounded-lg flex flex-col items-center justify-center transition-all duration-200 group-hover:border-red-800 group-hover:bg-gray-50">
                          <svg className="w-8 h-8 text-gray-400 group-hover:text-red-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                          </svg>
                          <span className="mt-2 text-xs text-gray-500 group-hover:text-red-800">Upload Photo</span>
                        </div>
                      )}
                      <input
                        type="file"
                        id="picture-input"
                        accept="image/*"
                        onChange={handlePictureChange}
                        className="hidden"
                      />
                    </div>
                  </div>
                </div>
              )}

              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  value={formData.name}
                  onChange={(e) => setFormData((prev) => ({ ...prev, name: e.target.value }))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-800 focus:ring-red-800"
                  required
                />
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  value={formData.email}
                  onChange={(e) => setFormData((prev) => ({ ...prev, email: e.target.value }))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-800 focus:ring-red-800"
                  required
                />
              </div>

              <div>
                <label htmlFor="instagram" className="block text-sm font-medium text-gray-700">
                  Instagram Handle
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">@</span>
                  </div>
                  <input
                    type="text"
                    id="instagram"
                    value={formData.instagram}
                    onChange={(e) => setFormData((prev) => ({ ...prev, instagram: e.target.value }))}
                    className="block w-full pl-7 rounded-md border-gray-300 shadow-sm focus:border-red-800 focus:ring-red-800"
                    placeholder="username"
                    required
                  />
                </div>
              </div>

              <div>
                <label htmlFor="age" className="block text-sm font-medium text-gray-700">
                  Age
                </label>
                <input
                  type="number"
                  id="age"
                  value={formData.age}
                  onChange={(e) => setFormData((prev) => ({ ...prev, age: e.target.value }))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-800 focus:ring-red-800"
                  required
                />
              </div>

              {event?.invite_configuration.requires_bio && (
                <div className="space-y-2">
                  <label htmlFor="note" className="block text-sm font-medium text-gray-700">
                    Add Note
                  </label>
                  <textarea
                    id="note"
                    name="bio"
                    rows={3}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-black focus:ring-black sm:text-sm"
                    value={formData.bio}
                    onChange={(e) =>
                      setFormData((prev) => ({ ...prev, bio: e.target.value }))
                    }
                    placeholder="Add a note..."
                  />
                </div>
              )}

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Interests
                </label>
                <div className="mt-1">
                  <div className="flex gap-2 mb-2">
                    <input
                      type="text"
                      value={currentInterest}
                      onChange={(e) => setCurrentInterest(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          addInterest(currentInterest);
                        }
                      }}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-red-800 focus:ring-red-800"
                      placeholder="Type and press Enter"
                    />
                    <button
                      type="button"
                      onClick={() => addInterest(currentInterest)}
                      className="px-4 py-2 text-black rounded-full hover:text-gray-600 transition-colors"
                    >
                      Add
                    </button>
                  </div>
                  <div className="flex flex-wrap gap-2">
                    {formData.interests.map((interest, index) => (
                      <InterestPill
                        key={index}
                        interest={interest}
                        onRemove={() => removeInterest(index)}
                      />
                    ))}
                  </div>
                </div>
              </div>

              {event?.invite_configuration.requires_payment && (
                <div className="border-t border-gray-200 pt-4">
                  <h3 className="text-lg font-serif text-gray-900">Event Details</h3>
                  <div className="mt-4 bg-gray-50 rounded-lg p-4">
                    <div className="flex justify-between items-center mb-2">
                      <span className="text-sm font-medium text-gray-700">Event Fee</span>
                      <span className="text-sm font-medium text-gray-900">${eventDetails?.event_fee || 0}</span>
                    </div>
                    <div className="flex justify-between items-center mb-4">
                      <span className="text-sm font-medium text-gray-700">Service Fee</span>
                      <span className="text-sm font-medium text-gray-900">${eventDetails?.service_fee || 0}</span>
                    </div>
                    <div className="flex justify-between items-center pt-2 border-t border-gray-200">
                      <span className="text-sm font-medium text-gray-900">Total</span>
                      <span className="text-sm font-medium text-gray-900">
                        ${(eventDetails?.event_fee || 0) + (eventDetails?.service_fee || 0)}
                      </span>
                    </div>
                  </div>
                  <div className="mt-4 bg-blue-50 rounded-lg p-4">
                    <p className="text-sm text-blue-800">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      This event requires payment. You will receive a payment link once your application is accepted.
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-full text-sm font-medium text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-800 disabled:bg-gray-400 disabled:cursor-not-allowed"
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ExternalInvite; 