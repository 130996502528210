import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import MembershipForm from './components/ApplyMembership';
import { InviteSignup } from './components/InviteSignup';
import AdminDashboard from './pages/AdminDashboard';
import ThankYouPage from './pages/ThankYouPage';
import ExternalInvite from './pages/ExternalInvite';
import InviteThankYou from './pages/InviteThankYou';
import PaymentSuccess from './pages/PaymentSuccess';
import PaymentCancelled from './pages/PaymentCancelled';

const App = () => {
  const [showMembershipForm, setShowMembershipForm] = useState(false);

  const handleEnter = () => {
    setShowMembershipForm(true);
  };

  const handleClose = () => {
    setShowMembershipForm(false);
  };

  const handleSubmit = () => {
    setShowMembershipForm(false);
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage onEnter={handleEnter} isAdmin={true} />} />
        <Route path="/signup" element={<InviteSignup />} />
        <Route path="/thank-you" element={<ThankYouPage />} />
        <Route path="/admin" element={<AdminDashboard />} />
        <Route path="/external-invite/:eventId" element={<ExternalInvite />} />
        <Route path="/invite-thank-you" element={<InviteThankYou />} />
        <Route path="/PaymentSuccess" element={<PaymentSuccess />} />
        <Route path="/PaymentCancelled" element={<PaymentCancelled />} />
      </Routes>
      {showMembershipForm && (
        <MembershipForm
          onClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}
    </Router>
  );
};

export default App;