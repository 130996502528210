import React, { useEffect, useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import avenidaLogo from '../assets/AvenidaTrans.png';

const PaymentSuccess: React.FC = () => {
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [loginCode, setLoginCode] = useState<string | null>(null);
  const [eventTitle, setEventTitle] = useState<string | null>(null);
  const [debugInfo, setDebugInfo] = useState<any>({});

  useEffect(() => {
    console.log('PaymentSuccess component mounted');
    
    // Get session_id from URL
    const urlParams = new URLSearchParams(window.location.search);
    const sessionIdParam = urlParams.get('session_id');
    console.log('URL Session ID:', sessionIdParam);
    setSessionId(sessionIdParam);

    if (!sessionIdParam) {
      console.error('No session ID in URL parameters');
      setError('No session ID provided');
      setLoading(false);
      return;
    }

    const fetchPaymentDetails = async () => {
      try {
        console.log('Creating Supabase client');
        // Create a Supabase client
        const supabaseUrl = import.meta.env.VITE_SUPABASE_URL || '';
        const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY || '';
        
        console.log('Supabase config:', { 
          url: supabaseUrl, 
          key: supabaseKey ? (supabaseKey.substring(0, 5) + '...') : 'missing' 
        });
        
        if (!supabaseUrl || !supabaseKey) {
          console.error('Missing Supabase configuration');
          setError('Application configuration error. Please contact support.');
          setLoading(false);
          return;
        }
        
        const supabase = createClient(supabaseUrl, supabaseKey);

        console.log('Fetching payment details for session:', sessionIdParam);

        // Call the handle-payment-success function
        const startTime = Date.now();
        const { data, error } = await supabase.functions.invoke('handle-payment-success', {
          body: { session_id: sessionIdParam }
        });
        const endTime = Date.now();
        
        console.log(`Function call took ${endTime - startTime}ms`);
        
        setDebugInfo({
          functionCallTime: `${endTime - startTime}ms`,
          responseType: data ? typeof data : 'null',
          errorType: error ? typeof error : 'null'
        });

        if (error) {
          console.error('Error fetching payment details:', error);
          setError('Failed to verify payment. Please contact support.');
        } else if (data) {
          console.log('Payment details received:', data);
          
          // Handle potential property name inconsistencies
          const loginCodeValue = data.loginCode || data.login_code;
          const eventTitleValue = data.eventTitle || data.event_title;
          
          if (loginCodeValue) {
            setLoginCode(loginCodeValue);
            setEventTitle(eventTitleValue);
          } else if (data.status === 'pending') {
            console.log('Login code still being generated, will need to refresh');
            setError('Your payment was successful, but your login code is still being generated. Please refresh this page in a few moments.');
          } else {
            console.error('Unexpected data format:', data);
            setError('Unexpected response format. Please refresh the page or contact support.');
          }
        } else {
          console.error('No data received from function');
          setError('No data received from server. Please refresh the page or contact support.');
        }
        setLoading(false);
      } catch (err) {
        console.error('Unexpected error:', err);
        setError('An unexpected error occurred. Please try again later.');
        setLoading(false);
      }
    };

    fetchPaymentDetails();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
        <div className="w-full max-w-md bg-white rounded-lg shadow-md p-8 text-center">
          <div className="mb-6">
            <img src={avenidaLogo} alt="Avenida Logo" className="mx-auto h-16" />
          </div>
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto mb-4"></div>
          <p className="mt-4 text-gray-600">Verifying your payment...</p>
          <p className="mt-2 text-xs text-gray-400">Session ID: {sessionId}</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
        <div className="w-full max-w-md bg-white rounded-lg shadow-md p-8 text-center">
          <div className="mb-6">
            <img src={avenidaLogo} alt="Avenida Logo" className="mx-auto h-16" />
          </div>
          <div className="text-red-500 text-5xl mb-4">⚠️</div>
          <h2 className="text-2xl font-bold text-red-600">Payment Verification Issue</h2>
          <p className="mt-2 text-gray-700">{error}</p>
          <p className="mt-4 text-gray-600">
            If you believe this is an error, please contact the event organizer or support team.
          </p>
          <div className="mt-4 p-2 bg-gray-100 rounded text-xs text-left">
            <p>Debug Info:</p>
            <p>Session ID: {sessionId}</p>
            <pre>{JSON.stringify(debugInfo, null, 2)}</pre>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
      <div className="w-full max-w-md bg-white rounded-lg shadow-md p-8 text-center">
        <div className="mb-6">
          <img src={avenidaLogo} alt="Avenida Logo" className="mx-auto h-16" />
        </div>
        <div className="text-green-500 text-5xl mb-4">✓</div>
        <h2 className="text-2xl font-bold">Payment Successful!</h2>
        <p className="mt-2">Thank you for your payment.</p>
        
        {eventTitle && (
          <div className="mt-4 mb-6">
            <h3 className="text-2xl font-bold text-gray-800">{eventTitle}</h3>
            <div className="w-24 h-1 bg-red-700 mx-auto mt-2"></div>
          </div>
        )}
        
        {loginCode ? (
          <div className="mt-6">
            <p className="text-gray-700">Your login code:</p>
            <div className="mt-2 bg-gray-100 p-4 rounded-lg">
              <p className="text-3xl font-mono tracking-widest font-bold text-gray-800">{loginCode}</p>
            </div>
            <p className="mt-4 text-sm text-gray-600">
              Please use this code to access the event in the Avenida app.
            </p>
          </div>
        ) : (
          <p className="mt-4 text-yellow-600">
            Your payment has been processed, but we couldn't retrieve your login code.
            Please check your email or contact support.
          </p>
        )}
        
        <div className="mt-8 border-t border-gray-200 pt-6">
          <p className="text-sm text-gray-600">
            A confirmation email with your login code has been sent to your email address.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess; 