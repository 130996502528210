import React from 'react';
import avenidaLogo from '../assets/AvenidaTrans.png';

const PaymentCancelled: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full">
        <div className="text-center">
          <div className="mb-6">
            <img src={avenidaLogo} alt="Avenida Logo" className="mx-auto h-16" />
          </div>
          <div className="bg-yellow-100 text-yellow-700 p-4 rounded-lg mb-6">
            <h2 className="text-2xl font-bold">Payment Cancelled</h2>
            <p className="mt-2">Your payment was not completed.</p>
          </div>
          
          <p className="mt-6 text-gray-700">
            You've cancelled your payment process. If this was a mistake, you can try again by clicking the payment link in your email.
          </p>
          
          <p className="mt-4 text-gray-700">
            If you're having trouble with the payment process or have any questions, please contact the event organizer.
          </p>
          
          <div className="mt-8">
            <a 
              href="/" 
              className="inline-block bg-red-700 text-white px-6 py-3 rounded-lg font-medium hover:bg-red-800 transition-colors"
            >
              Return to Home
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentCancelled; 