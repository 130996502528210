import React, { useState, useMemo, useEffect } from "react";
import { ChevronLeft, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { createClient } from "@supabase/supabase-js";
import AvenidaTrans from '../assets/AvenidaTrans.png';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const animationStyles = `
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes shake {
    0%, 100% { transform: translateX(0); }
    10%, 30%, 50%, 70%, 90% { transform: translateX(-5px); }
    20%, 40%, 60%, 80% { transform: translateX(5px); }
  }

  .animate-fadeIn {
    animation: fadeIn 0.3s ease-out forwards;
  }

  .animate-fadeInUp {
    animation: fadeInUp 0.3s ease-out forwards;
  }

  .animate-shake {
    animation: shake 0.5s ease-in-out;
  }

  .animation-delay-100 {
    animation-delay: 0.1s;
  }

  .animation-delay-200 {
    animation-delay: 0.2s;
  }

  .animation-delay-300 {
    animation-delay: 0.3s;
  }

  .animation-delay-400 {
    animation-delay: 0.4s;
  }
`;

interface PersonalInfo {
  firstName: string;
  lastName: string;
  identity: string;
  dob: string;
  email: string;
  location: string;
}

interface AdditionalInfo {
  jobTitle: string;
  interests: string[];
  otherClubs: string;
  clubNames?: string;
  instagramHandle: string;
  contribution: string;
}

interface PaymentInfo {
  plan: 'monthly' | 'yearly';
}

type FormData = {
  personalInfo: PersonalInfo;
  additionalInfo: AdditionalInfo;
  paymentInfo: PaymentInfo;
  paymentMethodId?: string;
};

interface MembershipFormProps {
  onClose: () => void;
  onSubmit: () => void;
}

type PaymentPlan = 'monthly' | 'yearly';

const INITIAL_FORM_DATA: FormData = {
  personalInfo: {
    firstName: "",
    lastName: "",
    identity: "",
    dob: "",
    email: "",
    location: "",
  },
  additionalInfo: {
    jobTitle: "",
    interests: [],
    otherClubs: "",
    clubNames: "",
    instagramHandle: "",
    contribution: "",
  },
  paymentInfo: {
    plan: 'monthly'
  },
};

const InterestPill = ({
  interest,
  onRemove,
}: {
  interest: string;
  onRemove: () => void;
}) => (
  <div className="inline-flex items-center gap-1 px-3 py-1 bg-gray-100 rounded-full text-sm">
    {interest}
    <button onClick={onRemove} type="button" aria-label="Remove interest">
      <X className="w-3 h-3" />
    </button>
  </div>
);

const PaymentForm = ({ email, onSuccess, onError }: { 
  email: string;
  onSuccess: (paymentMethodId: string) => void;
  onError: (error: string) => void;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [stripeError, setStripeError] = useState<string | null>(null);
  const [isCardSaved, setIsCardSaved] = useState(false);

  // Log Stripe initialization status
  React.useEffect(() => {
    if (!stripe) {
      console.error('Stripe.js has not loaded.');
      setStripeError('Payment system is not available.');
    } else {
      setStripeError(null); // Clear error if Stripe loads successfully
    }
  }, [stripe]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!stripe || !elements) {
      onError('Payment system is not initialized');
      return;
    }

    setIsProcessing(true);
    setStripeError(null);

    try {
      // Create a SetupIntent using Supabase Edge Function
      const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
      const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;
      
      const response = await fetch(
        `${supabaseUrl}/functions/v1/create-setup-intent`,
        {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${supabaseAnonKey}`
          },
          body: JSON.stringify({ email })
        }
      );

      if (!response.ok) {
        const errorData = await response.text();
        console.error('Setup intent error:', errorData);
        throw new Error('Network response was not ok');
      }

      const { clientSecret, error: setupError } = await response.json();

      if (setupError) {
        throw new Error(setupError.message);
      }

      if (!clientSecret) {
        throw new Error('No client secret received');
      }

      // Confirm card setup
      const { setupIntent, error: confirmError } = await stripe.confirmCardSetup(
        clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardElement)!,
            billing_details: { email }
          },
        }
      );

      if (confirmError) {
        throw new Error(confirmError.message);
      }

      if (!setupIntent || !setupIntent.payment_method) {
        throw new Error('Failed to setup payment method');
      }

      setIsCardSaved(true);
      onSuccess(setupIntent.payment_method as string);
    } catch (err: any) {
      console.error('Payment setup error:', err);
      setStripeError(err.message);
      onError(err.message);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="transition-all duration-300 ease-in-out">
      <div className="p-4 border border-gray-200 rounded-lg bg-white relative overflow-hidden transition-all duration-300 hover:shadow-md">
        {!stripe && (
          <div className="absolute inset-0 bg-gray-50 bg-opacity-75 flex items-center justify-center">
            <div className="text-sm text-gray-500">Loading payment system...</div>
          </div>
        )}
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
                iconColor: '#666EE8',
              },
              invalid: {
                color: '#9e2146',
                iconColor: '#fa755a',
              },
            },
            hidePostalCode: true,
          }}
          className={`transition-opacity duration-300 ease-in-out ${isCardSaved ? 'opacity-50' : ''}`}
        />
      </div>
      {stripeError && (
        <div className="mt-2 text-sm text-red-600 animate-fadeIn">
          {stripeError}
        </div>
      )}
      <button
        type="submit"
        disabled={!stripe || isProcessing || isCardSaved}
        className={`w-full mt-4 px-6 py-3 rounded-md font-medium transform transition-all duration-300 ease-in-out ${
          !stripe || isProcessing
            ? 'bg-gray-400 text-white cursor-not-allowed'
            : isCardSaved
            ? 'bg-green-500 text-white cursor-default'
            : 'bg-black text-white hover:bg-gray-800 hover:shadow-md active:scale-[0.98]'
        }`}
      >
        {!stripe ? (
          'Loading...'
        ) : isProcessing ? (
          'Processing...'
        ) : isCardSaved ? (
          <span className="flex items-center justify-center space-x-2">
            <span>Card Saved</span>
            <svg 
              className="w-5 h-5 animate-fadeIn" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2"
            >
              <path 
                d="M5 13l4 4L19 7" 
                strokeLinecap="round" 
                strokeLinejoin="round"
              />
            </svg>
          </span>
        ) : (
          'Save Card Details'
        )}
      </button>
    </form>
  );
};

const MembershipForm: React.FC<MembershipFormProps> = ({
  onClose,
  onSubmit,
}) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<FormData>(INITIAL_FORM_DATA);
  const [currentInterest, setCurrentInterest] = useState("");
  const [subscribeToUpdates, setSubscribeToUpdates] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [paymentPlan, setPaymentPlan] = useState<PaymentPlan>('monthly');
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  const [showPaymentInfo, setShowPaymentInfo] = useState(false);
  const navigate = useNavigate();

  // State to track if each step is valid
  const [isStepValid, setIsStepValid] = useState<{ [key: number]: boolean }>({
    1: true, // Step 1 is always valid
    2: false,
    3: false,
    4: false,
    5: false
  });

  // Validate step 2 (personal info)
  const validateStep2 = (): boolean => {
    const { personalInfo } = formData;
    const isValid = 
      !!personalInfo.firstName.trim() && 
      !!personalInfo.lastName.trim() && 
      !!personalInfo.dob &&
      !!personalInfo.email.trim() && 
      /\S+@\S+\.\S+/.test(personalInfo.email);
    
    return isValid;
  };

  // Validate step 3 (additional info)
  const validateStep3 = (): boolean => {
    const { additionalInfo } = formData;
    let isValid = !!additionalInfo.jobTitle.trim() && !!additionalInfo.contribution.trim();
    
    // Check if user selected "yes" for other clubs but didn't specify which clubs
    if (additionalInfo.otherClubs === "yes" && !additionalInfo.clubNames?.trim()) {
      isValid = false;
    }
    
    return isValid;
  };

  // Validate step 4 (payment)
  const validateStep4 = (): boolean => {
    // Step 4 is valid if a payment plan is selected
    // For a more complete check, you might want to verify if the paymentMethodId exists
    return !!formData.paymentMethodId;
  };

  // Validate step 5 (review)
  const validateStep5 = (): boolean => {
    // For step 5 (final review), valid if all previous steps are valid
    return isStepValid[2] && isStepValid[3] && isStepValid[4];
  };

  // Update validity whenever form data changes
  useEffect(() => {
    setIsStepValid(prev => ({
      ...prev,
      2: validateStep2(),
      3: validateStep3(),
      4: validateStep4(),
      5: validateStep5()
    }));
  }, [formData, formData.paymentMethodId]);

  const getSupabaseClient = () => {
    const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
    const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY;
    
    console.log("Creating Supabase client with URL:", supabaseUrl);
    
    // Try a different approach with explicit headers
    return createClient(supabaseUrl, supabaseKey, {
      auth: {
        autoRefreshToken: false,
        persistSession: false
      },
      global: {
        headers: {
          'X-Client-Info': 'supabase-js/2.x',
        },
      },
    });
  };

  const validateForm = (): { isValid: boolean; errors: { [key: string]: string } } => {
    const errors: { [key: string]: string } = {};
    const { personalInfo, additionalInfo } = formData;

    // Personal Info Validation
    if (!personalInfo.firstName.trim()) {
      errors.firstName = "First name is required";
    }
    if (!personalInfo.lastName.trim()) {
      errors.lastName = "Last name is required";
    }
    if (!personalInfo.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(personalInfo.email)) {
      errors.email = "Please enter a valid email address";
    }
    if (!personalInfo.dob) {
      errors.dob = "Date of birth is required";
    }

    // Additional Info Validation
    if (!additionalInfo.jobTitle.trim()) {
      errors.jobTitle = "Job title is required";
    }
    if (!additionalInfo.contribution.trim()) {
      errors.contribution = "Please tell us what you bring to Avenida";
    }
    if (additionalInfo.otherClubs === "yes" && !additionalInfo.clubNames?.trim()) {
      errors.clubNames = "Please specify which clubs you are a member of";
    }

    return {
      isValid: Object.keys(errors).length === 0,
      errors
    };
  };

  const handleSubmit = async () => {
    const { isValid, errors } = validateForm();
    if (!isValid) {
      setError("Please fill in all required fields correctly");
      setFieldErrors(errors);
      return;
    }
  
    setIsSubmitting(true);
    setError(null);
    setFieldErrors({});
  
    try {
      const supabase = getSupabaseClient();
      
      // Store email for subscription toggle on thank you page
      localStorage.setItem('applicantEmail', formData.personalInfo.email);
      
      // Prepare application data
      const applicationData = {
        first_name: formData.personalInfo.firstName,
        last_name: formData.personalInfo.lastName,
        identity: formData.personalInfo.identity || null,
        dob: formData.personalInfo.dob || null,
        email: formData.personalInfo.email,
        location: formData.personalInfo.location || null,
        job_title: formData.additionalInfo.jobTitle || null,
        interests: formData.additionalInfo.interests.length
          ? formData.additionalInfo.interests
          : null,
        other_clubs: formData.additionalInfo.otherClubs || null,
        club_names: formData.additionalInfo.clubNames || null,
        instagram_handle: formData.additionalInfo.instagramHandle || null,
        contribution: formData.additionalInfo.contribution || null,
        payment_plan: paymentPlan,
        status: 'pending',
        payment_status: 'awaiting_link',
        subscribe_to_updates: subscribeToUpdates
      };
      
      console.log("Submitting application data:", applicationData);
  
      const { data, error } = await supabase
        .from("membership_applications")
        .insert(applicationData)
        .select();
  
      if (error) {
        console.error("Supabase error details:", error);
        throw error;
      }
  
      console.log("Application submitted successfully:", data);
      setFormData(INITIAL_FORM_DATA);
      onSubmit();
      navigate("/thank-you");
    } catch (err: any) {
      console.error("Error inserting application:", err);
      setError(`Failed to submit the application: ${err.message || "Unknown error"}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const addInterest = (interest: string) => {
    if (
      interest.trim() &&
      !formData.additionalInfo.interests.includes(interest.trim())
    ) {
      updateAdditionalInfo("interests", [
        ...formData.additionalInfo.interests,
        interest.trim(),
      ]);
      setCurrentInterest("");
    }
  };

  const removeInterest = (indexToRemove: number) => {
    updateAdditionalInfo(
      "interests",
      formData.additionalInfo.interests.filter(
        (_, index) => index !== indexToRemove
      )
    );
  };

  const updatePersonalInfo = (field: keyof PersonalInfo, value: string) => {
    setFormData((prev) => ({
      ...prev,
      personalInfo: { ...prev.personalInfo, [field]: value },
    }));
  };

  const updateAdditionalInfo = (
    field: keyof AdditionalInfo,
    value: string | string[]
  ) => {
    setFormData((prev) => ({
      ...prev,
      additionalInfo: { ...prev.additionalInfo, [field]: value },
    }));
  };

  const updatePaymentInfo = (field: keyof PaymentInfo, value: PaymentPlan) => {
    setFormData((prev) => ({
      ...prev,
      paymentInfo: {
        ...prev.paymentInfo,
        [field]: value,
      },
    }));
  };

  const handleContinue = () => {
    // Check if current step is valid
    if (!isStepValid[step]) {
      // Show appropriate error messages
      if (step === 2) {
        const errors: { [key: string]: string } = {};
        if (!formData.personalInfo.firstName.trim()) errors.firstName = "First name is required";
        if (!formData.personalInfo.lastName.trim()) errors.lastName = "Last name is required";
        if (!formData.personalInfo.email.trim()) errors.email = "Email is required";
        else if (!/\S+@\S+\.\S+/.test(formData.personalInfo.email)) errors.email = "Please enter a valid email address";
        if (!formData.personalInfo.dob) errors.dob = "Date of birth is required";
        setFieldErrors(errors);
      } else if (step === 3) {
        const errors: { [key: string]: string } = {};
        if (!formData.additionalInfo.jobTitle.trim()) errors.jobTitle = "Job title is required";
        if (!formData.additionalInfo.contribution.trim()) errors.contribution = "Please tell us what you bring to Avenida";
        if (formData.additionalInfo.otherClubs === "yes" && !formData.additionalInfo.clubNames?.trim()) {
          errors.clubNames = "Please specify which clubs you are a member of";
        }
        setFieldErrors(errors);
      } else if (step === 4) {
        setFieldErrors({ payment: "Please add a payment method to continue" });
      }
      
      // Show an error at the top
      setError("Please fill in all required fields correctly");
      return;
    }

    // Continue to next step if valid
    if (step < 5) {
      setError(null);
      setFieldErrors({});
      setStep(step + 1);
    } else {
      handleSubmit();
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    } else {
      onClose();
    }
  };

  return (
    <>
      <style>{animationStyles}</style>
      <div className="fixed inset-0 bg-[#F0EFE9] z-50 overflow-y-auto">
        <div className="max-w-2xl mx-auto px-6 py-8">
          <div className="flex justify-between items-center mb-12">
            <div className="h-12 transition-transform duration-300 hover:scale-[1.02]">
              <img src={AvenidaTrans} alt="Avenida" className="h-full w-auto object-contain" />
            </div>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-full transition-all duration-300 hover:rotate-90"
              aria-label="Close"
            >
              <X className="w-6 h-6" />
            </button>
          </div>

          <div className="mb-24">
            {step === 1 && (
              <div className="space-y-8 animate-fadeIn">
                <h1 className="text-4xl font-serif text-center">Membership</h1>
                <div className="space-y-6 max-w-md mx-auto">
                  <p className="text-sm text-center text-gray-600">
                    Please fill out all the fields carefully in order for this
                    application to be submitted to the Committee.
                  </p>
                </div>
              </div>
            )}

            {step === 2 && (
              <div className="space-y-6 animate-fadeIn">
                <h1 className="text-4xl font-serif">Getting to know you</h1>
                <div className="space-y-4">
                  <div className="space-y-1">
                    <input
                      id="firstName"
                      name="firstName"
                      type="text"
                      placeholder="First name"
                      className={`w-full p-3 border rounded-md bg-white ${
                        fieldErrors.firstName ? 'border-red-500' : 'border-gray-200'
                      }`}
                      value={formData.personalInfo.firstName}
                      onChange={(e) => {
                        updatePersonalInfo("firstName", e.target.value);
                        if (fieldErrors.firstName) {
                          setFieldErrors(prev => ({ ...prev, firstName: '' }));
                        }
                      }}
                    />
                    {fieldErrors.firstName && (
                      <p className="text-sm text-red-500 mt-1">{fieldErrors.firstName}</p>
                    )}
                  </div>

                  <div className="space-y-1">
                    <input
                      id="lastName"
                      name="lastName"
                      type="text"
                      placeholder="Last name"
                      className={`w-full p-3 border rounded-md bg-white ${
                        fieldErrors.lastName ? 'border-red-500' : 'border-gray-200'
                      }`}
                      value={formData.personalInfo.lastName}
                      onChange={(e) => {
                        updatePersonalInfo("lastName", e.target.value);
                        if (fieldErrors.lastName) {
                          setFieldErrors(prev => ({ ...prev, lastName: '' }));
                        }
                      }}
                    />
                    {fieldErrors.lastName && (
                      <p className="text-sm text-red-500 mt-1">{fieldErrors.lastName}</p>
                    )}
                  </div>

                  <select
                    id="identity"
                    name="identity"
                    className="w-full p-3 border border-gray-200 rounded-md bg-white text-gray-600"
                    value={formData.personalInfo.identity}
                    onChange={(e) => updatePersonalInfo("identity", e.target.value)}
                  >
                    <option value="" className="text-gray-600">How do you identify?</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>

                  <div className="space-y-1">
                    <label htmlFor="dob" className="block text-sm text-gray-600">
                      Date of Birth
                    </label>
                    <input
                      id="dob"
                      name="dob"
                      type="date"
                      aria-label="Date of Birth"
                      className={`w-full p-3 border rounded-md bg-white ${
                        fieldErrors.dob ? 'border-red-500' : 'border-gray-200'
                      }`}
                      value={formData.personalInfo.dob}
                      onChange={(e) => {
                        updatePersonalInfo("dob", e.target.value);
                        if (fieldErrors.dob) {
                          setFieldErrors(prev => ({ ...prev, dob: '' }));
                        }
                      }}
                    />
                    {fieldErrors.dob && (
                      <p className="text-sm text-red-500 mt-1">{fieldErrors.dob}</p>
                    )}
                  </div>

                  <div className="space-y-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Email"
                      className={`w-full p-3 border rounded-md bg-white ${
                        fieldErrors.email ? 'border-red-500' : 'border-gray-200'
                      }`}
                      value={formData.personalInfo.email}
                      onChange={(e) => {
                        updatePersonalInfo("email", e.target.value);
                        if (fieldErrors.email) {
                          setFieldErrors(prev => ({ ...prev, email: '' }));
                        }
                      }}
                    />
                    {fieldErrors.email && (
                      <p className="text-sm text-red-500 mt-1">{fieldErrors.email}</p>
                    )}
                  </div>

                  <input
                    id="location"
                    name="location"
                    type="text"
                    placeholder="Location"
                    className="w-full p-3 border border-gray-200 rounded-md bg-white"
                    value={formData.personalInfo.location}
                    onChange={(e) => updatePersonalInfo("location", e.target.value)}
                  />
                </div>
              </div>
            )}

            {step === 3 && (
              <div className="space-y-6 animate-fadeIn">
                <h1 className="text-4xl font-serif">Getting to know you</h1>
                <div className="space-y-4">
                  <div className="space-y-1">
                    <input
                      id="jobTitle"
                      name="jobTitle"
                      type="text"
                      placeholder="Job role / title"
                      className={`w-full p-3 border rounded-md bg-white ${
                        fieldErrors.jobTitle ? 'border-red-500' : 'border-gray-200'
                      }`}
                      value={formData.additionalInfo.jobTitle}
                      onChange={(e) => {
                        updateAdditionalInfo("jobTitle", e.target.value);
                        if (fieldErrors.jobTitle) {
                          setFieldErrors(prev => ({ ...prev, jobTitle: '' }));
                        }
                      }}
                    />
                    {fieldErrors.jobTitle && (
                      <p className="text-sm text-red-500 mt-1">{fieldErrors.jobTitle}</p>
                    )}
                  </div>

                  <div className="space-y-2">
                    <div className="flex flex-wrap gap-2">
                      {formData.additionalInfo.interests.map(
                        (interest, index) => (
                          <InterestPill
                            key={index}
                            interest={interest}
                            onRemove={() => removeInterest(index)}
                          />
                        )
                      )}
                    </div>
                    <input
                      id="interests"
                      name="interests"
                      type="text"
                      placeholder="Interests (press Enter to add)"
                      className="w-full p-3 border border-gray-200 rounded-md bg-white"
                      value={currentInterest}
                      onChange={(e) => setCurrentInterest(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          addInterest(currentInterest);
                        }
                      }}
                    />
                  </div>

                  <div className="space-y-3">
                    <select
                      id="otherClubs"
                      name="otherClubs"
                      className="w-full p-3 border border-gray-200 rounded-md bg-white"
                      value={formData.additionalInfo.otherClubs}
                      onChange={(e) => {
                        updateAdditionalInfo("otherClubs", e.target.value);
                        if (e.target.value !== "yes") {
                          setFieldErrors(prev => ({ ...prev, clubNames: '' }));
                        }
                      }}
                    >
                      <option value="">
                        Are you a member of any other private clubs?
                      </option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>

                    {formData.additionalInfo.otherClubs === "yes" && (
                      <div className="space-y-1">
                        <input
                          id="clubNames"
                          name="clubNames"
                          type="text"
                          placeholder="Which private clubs are you a member of?"
                          className={`w-full p-3 border rounded-md bg-white ${
                            fieldErrors.clubNames ? 'border-red-500' : 'border-gray-200'
                          }`}
                          value={formData.additionalInfo.clubNames || ""}
                          onChange={(e) => {
                            updateAdditionalInfo("clubNames", e.target.value);
                            if (fieldErrors.clubNames) {
                              setFieldErrors(prev => ({ ...prev, clubNames: '' }));
                            }
                          }}
                        />
                        {fieldErrors.clubNames && (
                          <p className="text-sm text-red-500 mt-1">{fieldErrors.clubNames}</p>
                        )}
                      </div>
                    )}
                  </div>

                  <input
                    id="instagramHandle"
                    name="instagramHandle"
                    type="text"
                    placeholder="What is your Instagram handle?"
                    className="w-full p-3 border border-gray-200 rounded-md bg-white"
                    value={formData.additionalInfo.instagramHandle}
                    onChange={(e) =>
                      updateAdditionalInfo("instagramHandle", e.target.value)
                    }
                  />

                  <div className="space-y-1">
                    <textarea
                      id="contribution"
                      name="contribution"
                      placeholder="Finally, what do you bring to Avenida?"
                      className={`w-full p-3 border rounded-md bg-white h-32 resize-none ${
                        fieldErrors.contribution ? 'border-red-500' : 'border-gray-200'
                      }`}
                      value={formData.additionalInfo.contribution}
                      onChange={(e) => {
                        updateAdditionalInfo("contribution", e.target.value);
                        if (fieldErrors.contribution) {
                          setFieldErrors(prev => ({ ...prev, contribution: '' }));
                        }
                      }}
                    />
                    {fieldErrors.contribution && (
                      <p className="text-sm text-red-500 mt-1">{fieldErrors.contribution}</p>
                    )}
                  </div>
                </div>
              </div>
            )}

            {step === 4 && (
              <div className="space-y-6 animate-fadeIn">
                <h1 className="text-4xl font-serif">Membership Plan</h1>
                <div className="space-y-4 mb-8">
                  <h2 className="font-serif text-xl mb-4">Select Your Membership Plan</h2>
                  
                  <div className="grid grid-cols-2 gap-4 mb-6">
                    <button
                      type="button"
                      onClick={() => {
                        setPaymentPlan('monthly');
                        updatePaymentInfo('plan', 'monthly');
                      }}
                      className={`p-4 rounded-lg border-2 transition-all ${
                        paymentPlan === 'monthly'
                          ? 'border-black bg-black text-white'
                          : 'border-gray-200 hover:border-gray-300'
                      }`}
                    >
                      <div className="text-lg font-medium mb-1">Monthly</div>
                      <div className="text-sm opacity-80">$20/month</div>
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setPaymentPlan('yearly');
                        updatePaymentInfo('plan', 'yearly');
                      }}
                      className={`p-4 rounded-lg border-2 transition-all ${
                        paymentPlan === 'yearly'
                          ? 'border-black bg-black text-white'
                          : 'border-gray-200 hover:border-gray-300'
                      }`}
                    >
                      <div className="text-lg font-medium mb-1">Yearly</div>
                      <div className="text-sm opacity-80">$200/year</div>
                    </button>
                  </div>

                  <div className="text-center text-sm text-gray-500 -mt-3 mb-6">
                    <span className="inline-flex items-center gap-1.5">
                      <svg 
                        className={`w-4 h-4 transition-colors duration-200 ${
                          paymentPlan === 'yearly' ? 'text-green-600' : 'text-gray-500'
                        }`} 
                        viewBox="0 0 24 24" 
                        fill="none" 
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path 
                          d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" 
                          stroke="currentColor" 
                          strokeWidth="2" 
                          strokeLinecap="round" 
                          strokeLinejoin="round"
                        />
                      </svg>
                      Save 17% with annual membership
                    </span>
                  </div>

                  <div className="space-y-4">
                    <div className="flex justify-between items-center border-b border-gray-200 pb-3">
                      <span className="text-gray-600">
                        {paymentPlan === 'monthly' ? 'Monthly payment' : 'Annual payment'}
                      </span>
                      <span className="font-medium">
                        ${paymentPlan === 'monthly' ? '20' : '200'}
                      </span>
                    </div>
                    
                    <div className="bg-white p-4 rounded-lg border border-gray-200">
                      <h3 className="font-medium mb-3">Membership Benefits</h3>
                      <ul className="space-y-2 text-sm text-gray-600">
                        <li className="flex items-start">
                          <span className="text-black mr-2">•</span>
                          Access to exclusive events and experiences
                        </li>
                        <li className="flex items-start">
                          <span className="text-black mr-2">•</span>
                          Ability to host and curate your own events
                        </li>
                        <li className="flex items-start">
                          <span className="text-black mr-2">•</span>
                          Ability to join groups and recurring events
                        </li>
                        <li className="flex items-start">
                          <span className="text-black mr-2">•</span>
                          Access to special collaborations and partnerships
                        </li>
                      </ul>
                    </div>

                    <div className="mt-6">
                      <div className="flex items-center gap-2 mb-4">
                        <h3 className="font-medium">Payment Details</h3>
                        <button
                          type="button"
                          onClick={() => setShowPaymentInfo(!showPaymentInfo)}
                          className="text-gray-500 hover:text-gray-700 transition-colors"
                          aria-label="Toggle payment information"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                        </button>
                      </div>
                      
                      {showPaymentInfo && (
                        <div className="bg-blue-50 rounded-lg p-4 mb-8 animate-fade-in">
                          <p className="text-sm text-blue-800 flex items-start">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block mr-2 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <span>
                              We'll securely store your payment method but won't charge you until your application is approved. Once approved, we'll automatically process your first payment and set up your subscription.
                            </span>
                          </p>
                        </div>
                      )}

                      <PaymentForm
                        email={formData.personalInfo.email}
                        onSuccess={(paymentMethodId) => {
                          setFieldErrors(prev => ({ ...prev, payment: '' }));
                          setFormData(prev => ({
                            ...prev,
                            paymentMethodId
                          }));
                        }}
                        onError={(error) => {
                          setFieldErrors(prev => ({
                            ...prev,
                            payment: error
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {step === 5 && (
              <div className="space-y-8 animate-fadeIn">
                <h1 className="text-4xl font-serif text-center mb-6">Application Summary</h1>

                {error ? (
                  <div className="p-4 bg-red-50 border border-red-100 rounded-lg text-center animate-shake">
                    <p className="text-red-600 font-medium mb-2" role="alert">{error}</p>
                    {Object.entries(fieldErrors).map(([field, message]) => (
                      <p key={field} className="text-sm text-red-500">
                        {message}
                      </p>
                    ))}
                  </div>
                ) : (
                  <div className="space-y-8">
                    
                    <div className="bg-white p-6 rounded-lg border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md animate-fadeInUp">
                      <h3 className="font-medium mb-4">Review Your Information</h3>
                      
                      <div className="space-y-6">
                        <div className="animate-fadeIn animation-delay-100">
                          <h4 className="text-sm uppercase text-gray-500 font-medium border-b pb-1 mb-3">Personal Information</h4>
                          <div className="grid grid-cols-2 gap-x-4 gap-y-3 text-sm">
                            <div>
                              <p className="text-gray-500">Full Name</p>
                              <p className="font-medium">{formData.personalInfo.firstName} {formData.personalInfo.lastName}</p>
                            </div>
                            <div>
                              <p className="text-gray-500">Email</p>
                              <p className="font-medium">{formData.personalInfo.email}</p>
                            </div>
                            {formData.personalInfo.identity && (
                              <div>
                                <p className="text-gray-500">Gender Identity</p>
                                <p className="font-medium">{formData.personalInfo.identity.charAt(0).toUpperCase() + formData.personalInfo.identity.slice(1)}</p>
                              </div>
                            )}
                            {formData.personalInfo.dob && (
                              <div>
                                <p className="text-gray-500">Date of Birth</p>
                                <p className="font-medium">{new Date(formData.personalInfo.dob).toLocaleDateString()}</p>
                              </div>
                            )}
                            {formData.personalInfo.location && (
                              <div>
                                <p className="text-gray-500">Location</p>
                                <p className="font-medium">{formData.personalInfo.location}</p>
                              </div>
                            )}
                          </div>
                        </div>
                        
                        <div className="animate-fadeIn animation-delay-200">
                          <h4 className="text-sm uppercase text-gray-500 font-medium border-b pb-1 mb-3">Professional & Interests</h4>
                          <div className="grid grid-cols-2 gap-x-4 gap-y-3 text-sm">
                            <div>
                              <p className="text-gray-500">Job Title</p>
                              <p className="font-medium">{formData.additionalInfo.jobTitle}</p>
                            </div>
                            
                            {formData.additionalInfo.interests.length > 0 && (
                              <div className="col-span-2">
                                <p className="text-gray-500">Interests</p>
                                <div className="flex flex-wrap gap-1 mt-1">
                                  {formData.additionalInfo.interests.map((interest, index) => (
                                    <span key={index} className="inline-block px-2 py-1 bg-gray-100 rounded-full text-xs">
                                      {interest}
                                    </span>
                                  ))}
                                </div>
                              </div>
                            )}
                            
                            <div className="col-span-2">
                              <p className="text-gray-500">What You Bring to Avenida</p>
                              <p className="font-medium">{formData.additionalInfo.contribution}</p>
                            </div>
                          </div>
                        </div>
                        
                        <div className="animate-fadeIn animation-delay-300">
                          <h4 className="text-sm uppercase text-gray-500 font-medium border-b pb-1 mb-3">Membership Details</h4>
                          <div className="grid grid-cols-2 gap-x-4 gap-y-3 text-sm">
                            <div>
                              <p className="text-gray-500">Payment Plan</p>
                              <p className="font-medium">{paymentPlan === 'monthly' ? 'Monthly ($20/month)' : 'Yearly ($200/year)'}</p>
                            </div>
                            <div>
                              <p className="text-gray-500">Payment Method</p>
                              <p className="font-medium flex items-center">
                                {formData.paymentMethodId 
                                  ? <><span className="mr-1">Card saved</span> 
                                    <svg viewBox="0 0 24 24" className="w-4 h-4 text-green-600" fill="none" stroke="currentColor" strokeWidth="2">
                                      <path d="M5 13l4 4L19 7" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                  </> 
                                  : 'No card saved'}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <label className="flex items-start justify-center gap-4 bg-white p-4 rounded-lg border border-gray-200 shadow-sm group cursor-pointer select-none transition-all duration-300 hover:shadow-md animate-fadeInUp animation-delay-400">
                      <div className="relative flex-shrink-0">
                        <input
                          type="checkbox"
                          id="subscribeToUpdates"
                          checked={subscribeToUpdates}
                          onChange={(e) => setSubscribeToUpdates(e.target.checked)}
                          className="peer sr-only"
                        />
                        <div className="h-6 w-6 border-2 border-gray-300 rounded-md transition-all duration-300
                          peer-checked:border-black group-hover:border-gray-400 relative bg-white">
                          <svg 
                            className={`absolute inset-0 w-full h-full transition-transform duration-300 ease-[cubic-bezier(0.34,1.56,0.64,1)]
                              ${subscribeToUpdates ? 'scale-100' : 'scale-0'}`}
                            viewBox="0 0 24 24" 
                            fill="none" 
                            stroke="currentColor" 
                            strokeWidth="2" 
                            strokeLinecap="round" 
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </div>
                      </div>
                      <div className="text-sm text-gray-600 text-left transition-colors duration-200 
                        group-hover:text-gray-900">
                        I would like to receive updates about 
                        <span className="font-medium text-black ml-1 group-hover:text-gray-900">Avenida news and events</span>
                      </div>
                    </label>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        {/* Footer with animated buttons */}
        <div className="fixed bottom-0 left-0 right-0 bg-[#F0EFE9] border-t border-gray-100">
          <div className="max-w-md mx-auto px-4 py-6">
            <div className="flex items-center justify-center gap-4 relative">
              <button
                type="button"
                onClick={handleBack}
                className="absolute left-0 flex items-center justify-center text-gray-600 transition-all duration-300 hover:scale-110"
                aria-label="Back"
              >
                <ChevronLeft className="w-8 h-8" />
              </button>
              <div className="flex flex-col items-center">
                <button
                  type="button"
                  disabled={isSubmitting || (step !== 1 && !isStepValid[step])}
                  onClick={handleContinue}
                  className={`px-12 py-3 border-2 border-black text-black rounded-full font-medium min-w-[160px] transform transition-all duration-300 ${
                    isSubmitting || (step !== 1 && !isStepValid[step]) 
                      ? "opacity-50 cursor-not-allowed bg-gray-200 border-gray-300 text-gray-500" 
                      : "hover:bg-black hover:text-white hover:shadow-md active:scale-[0.98]"
                  }`}
                >
                  {isSubmitting
                    ? "Submitting..."
                    : step === 5
                    ? "Complete"
                    : "Continue"}
                </button>
                
                {step === 4 && !isStepValid[4] && (
                  <p className="text-xs text-red-500 mt-2">Please add a payment method to continue</p>
                )}
                {step === 3 && !isStepValid[3] && fieldErrors.jobTitle && (
                  <p className="text-xs text-red-500 mt-2">Please complete all required fields</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const WrappedMembershipForm: React.FC<MembershipFormProps> = (props) => {
  const stripePromise = useMemo(() => {
    // Get Stripe key from environment variable
    const stripeKey = import.meta.env.VITE_STRIPE_PUBLIC_KEY;
    
    console.log('Stripe initialization check:', {
      hasStripeKey: !!stripeKey,
      stripeKeyPrefix: stripeKey?.substring(0, 7),
      isDevelopment: import.meta.env.DEV,
      mode: import.meta.env.MODE
    });

    if (!stripeKey) {
      console.error('Stripe public key is missing in environment variables');
      return Promise.resolve(null);
    }

    return loadStripe(stripeKey).catch(err => {
      console.error('Failed to load Stripe:', err);
      return null;
    });
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <MembershipForm {...props} />
    </Elements>
  );
};

export default WrappedMembershipForm;